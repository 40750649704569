import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth } from '../Authentification/Auth';
import { IdBCContext } from '../IdBCContext';
import API_BASE_URL from './config';

const AppelAPIMoto = ({index, idMoto, voyage,typeVehicule, typeCarburant, valeurInput, frequence, consommation,unit,typeKilo,setLancer, Lancer, save, setApiResponse, setConsoVoyage, setAppelMoto, setIdMoto,  filledData, usedUrl, lancerSave, setLancerSave, num}) => {
  const { token, fetchToken, isTokenExpired } = useAuth();
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { idBC } = useContext(IdBCContext);

  useEffect(() => {
    isTokenExpired()
  }, []);

  
  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    if (token && Lancer) { 
       fetchData();
       setLancer(false);
     }
  }, [token, Lancer]); // Se ré-exécute lorsque `token` change

  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    if(token && lancerSave){
       fetchDataToSave(filledData, usedUrl);
       setLancerSave(false)
     }
  }, [token, lancerSave]); // Se ré-exécute lorsque `token` change

  const fetchDataToSave = async (filledData, usedUrl) => {
    setLoading(true);
    filledData.save = true
    
    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    
    try {
      const result = await axios.post(usedUrl, filledData, options);

    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse('Erreur lors de la récupération des données.');

    } finally {
      setLoading(false);
      setLancerSave(false)    
    }
  }

   const fetchData = async () => {
     setLoading(true);
    const apiUrlConnu = `${API_BASE_URL}/aps_Pmoto_Global`;
    const apiUrlInconnu= `${API_BASE_URL}/aps_Pmoto_Detail`;
    
    let apiUrl = ''; 

    // Déterminer l'URL de l'API en fonction de la valeur de `kmConnu`
    if (typeKilo === 'oui') {
        apiUrl = apiUrlConnu;
      } else {
        apiUrl = apiUrlInconnu;
      }

    const data = (typeKilo==='oui')
      ? {
      IdBC: idBC,
      IdPage: "Pmoto",
      TypeVehicule: typeVehicule,
      Typecarburant: typeCarburant,
      Consommation: consommation || 0,
      ConsoConnue: consommation !== '',
      UnitConsommation: unit,
      KilometrageConnu: "True",
      Kilometrage: valeurInput,
      FrequenceKilometrage: frequence,
      save: save
    }: (typeKilo === 'non' && (idMoto != undefined)) ?
    {
        IdBC: idBC,
        IdMoto: idMoto,
        IdPage: "Pmoto",
        typeVoyage: voyage.typeVoyage,
        depart: voyage.depart.name,
        latitudeDepart: voyage.depart.latitude,
        longitudeDepart: voyage.depart.longitude,
        arrivee: voyage.arrive.name,
        latitudeArrivee: voyage.arrive.latitude,
        longitudeArrivee: voyage.arrive.longitude,
        frequence: parseFloat(voyage.ajout.frequence),
        unit: voyage.ajout.unit,
        save: save
        // nbPersonnes: voyage.ajoutPerso
      }:null;

    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    
    //Faire le if sur la condition km connu =true
    try {
      const result = await axios.post(apiUrl, data, options);
      // Stocker à jour l'appel à l'API pour plus tard
      setAppelMoto(index, data, apiUrl)
       // Mettre à jour la réponse de l'API dans le composant parent
       setApiResponse(result.data);
       if (valeurInput == '1' && typeKilo === 'oui') {
        setIdMoto(result.data.IdMoto)
       }
       if (typeKilo !== 'oui') {
        setConsoVoyage(index, result.data.ConsoVoyage)
       }

       setLancer(false);

    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse('Erreur lors de la récupération des données.');
      setIdMoto('');
    } finally {
      setLoading(false);
    }
  };
  return (null);
};

export default AppelAPIMoto;
