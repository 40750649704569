import React, { useState } from 'react';
import "./Input.css";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function InputFieldNb(props) {
  const { titre, value, onChange, text, valueSelect, onChangeSelect } = props;
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (e) => {
  const numValue = parseFloat(e.target.value);
  if (isNaN(numValue)) {
    onChange(0);
    return; // Ignore non-numeric inputs
  }
  onChange(numValue);
  };

  const handleInputFocus = () => {
  setIsFocused(true);
  };

  const handleInputBlur = () => {
  setIsFocused(false);
  };

  const handleUnitChange = (e) => {
  const value = e.target.value;
  onChangeSelect(value);
  };

  return (

  <div className='InputUnit'>
    <div className={`col-25 ${isFocused || value ? 'label-up' : ''}`}>
    <label className='h61'>{titre}</label>
    </div>
      <OutlinedInput
      sx={{ m: 1, width: '50vh', border: '0px solid black', borderRadius : '0px', marginTop : "2px"}}
      endAdornment={
        <InputAdornment position="end">
        <select
        className="unit-select"
        value={valueSelect || ''}
        onChange={handleUnitChange}
        >
        <option value="L/100 km">L/100 km</option>
        <option value="kW/h">kW/h</option>
        </select>
      </InputAdornment> 
      }       
      placeholder="5"
      value={value || ''}
      onChange={handleInputChange}
      onFocus={handleInputFocus}
      onBlur={handleInputBlur}
      />
    </div>


  );
}

export default InputFieldNb;
