import React, { createContext, useState } from 'react';

const CacheContextTaxi = createContext();

export const CacheTaxiProvider = ({ children }) => {
		const [cache, setCache] = useState(JSON.parse(sessionStorage.getItem("cacheTaxi")) || {});

	const setCacheCustom = () => {
		sessionStorage.setItem("cacheTaxi", JSON.stringify(cache))
	}

	const clearCacheTaxi = () => {
		setCache({})
	}

	return (
		<CacheContextTaxi.Provider value={{ cache, setCache, setCacheCustom, clearCacheTaxi }}>
			{children}
		</CacheContextTaxi.Provider>
	);
};

export default CacheContextTaxi;
