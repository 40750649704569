import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth } from '../Authentification/Auth';
import { IdBCContext } from '../IdBCContext';
import API_BASE_URL from './config';

const AppelAPIMotoId = ({index, idMoto, voyage, typeVehicule, typeCarburant, valeurInput, frequence, consommation, consommationConnue, unit, typeKilo, setLancer, Lancer, save, setApiResponse, setConsoVoyage, setAppelMoto, setIdMoto,  filledData, usedUrl, lancerSave, setLancerSave, num}) => {
  const { token, fetchToken, isTokenExpired } = useAuth();
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { idBC } = useContext(IdBCContext);

  useEffect(() => {
    isTokenExpired()
  }, []);

  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    console.log("MOTO", Lancer)
    if (token && Lancer) { 
       fetchData();
       setLancer(false);
     }
  }, [token, Lancer]); // Se ré-exécute lorsque `token` change

   const fetchData = async () => {
     setLoading(true);
    const apiUrl = `${API_BASE_URL}/aps_Pmoto_Global`;


    const data = {
      IdBC: idBC,
      IdPage: "Pmoto",
      TypeVehicule: typeVehicule,
      Typecarburant: typeCarburant,
      Consommation: consommation || 0,
      ConsoConnue: consommationConnue,
      UnitConsommation: unit,
      KilometrageConnu: "True",
      Kilometrage: valeurInput,
      FrequenceKilometrage: frequence,
      save: save
    }

    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    
    //Faire le if sur la condition km connu =true
    try {
      const result = await axios.post(apiUrl, data, options);
       // Mettre à jour la réponse de l'API dans le composant parent
      setIdMoto(result.data.IdMoto !== undefined ? result.data.IdMoto : "")

       setLancer(false);

    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse('Erreur lors de la récupération des données.');
      setIdMoto('');
    } finally {
      setLoading(false);
    }
  };
  return (null);
};

export default AppelAPIMotoId;
