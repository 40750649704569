import React from 'react';
import "./Footer.css"

const Footer = () => {
  return (
      <footer className="footer">
        <div className="footer-container">
          <p className="footer-left">Copyright &copy; {new Date().getFullYear()} Fondation GoodPlanet.</p>
          <a href="/mentions-legales-calculateur.docx" className="footer-right">Mentions légales</a>
        </div>
      </footer>

  );
};

export default Footer;
