import React, { createContext, useContext, useEffect, useState } from 'react';

const FormProgressContext = createContext();

export const FormProgressProvider = ({ children }) => {
    const [formStatus, setFormStatus] = useState(JSON.parse(sessionStorage.getItem("formStatus")) || {
    intro: 'vide',
    avion: 'vide', // 'vide', 'en cours', 'fini'
    voiture: 'vide',
    train: 'vide',
    tc: 'vide',
    placement: 'vide',
    alimentaire: 'vide',
    domicile: 'vide',
    dechet: 'vide',
    bilan: 'vide',
    placement: 'vide',
    });

    useEffect(() => {
        sessionStorage.setItem("formStatus", JSON.stringify(formStatus))
    })

    const clearFormProgress = () => {
        setFormStatus({})
    }

    return (
    <FormProgressContext.Provider value={{ formStatus, setFormStatus, clearFormProgress }}>
        {children}
    </FormProgressContext.Provider>
    );
};

export const useFormProgress = () => {
    return useContext(FormProgressContext);
};
