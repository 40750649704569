import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Titre from '../../Titre';
import CarreLogoInput2 from '../../CarreLogo/CarreLogoInput2';
import "./form.css";
import LogoPlastique from "../../img/plastique.png";
import LogoPapier from "../../img/papier.png";
import LogoPlante from "../../img/vegetalien.png";
import LogoCarton from "../../img/emballage.png";
import LogoVerre from "../../img/verre.png";
import LogoAmpoule from "../../img/ampoule.png";
import LogoVetement from "../../img/vetement.png";
import LogoMedicament from "../../img/medicament.png";
import LogoMicroOnde from "../../img/microOnde.png";
import LogoCanape from "../../img/canape.png";
import LogoPeiture from "../../img/pinture.png";
import LogoPile from "../../img/pile.png";
import Compteur from '../../Compteur/Compteur';
import Bouton from '../Bouton';
import BilanInter from '../../BilanInter';
import AppelAPIDechet from '../../Api/AppelAPIDechet';
import { useFormProgress } from '../../FormProgressContext';
import CacheContextDechets from '../../../components/cachescontext/contextDechets';
import {useAppelDict} from '../../BilanInter/appelDictContext'

const FormDechet = ({}) => {
  const { cache, setCache, setCacheCustom } = useContext(CacheContextDechets);

  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [displayBilan, setDisplayBilan] = useState(() => cache.displayBilan || false);
  const { formStatus, setFormStatus } = useFormProgress();

  const { appelDict, setAppelDict } = useAppelDict();

  // Méthode pour stocker les appels fait à voiture
  const updateAppelDechet = (index, appelDechet, apiUrl) => {
		setAppelDechet((prevAppelDechet) => {
            const newAppelDechet = [...prevAppelDechet];
			      newAppelDechet[index] = { ...newAppelDechet[index], appelDechet: appelDechet, apiUrl: apiUrl };
            addToAppelDict(newAppelDechet)
			      return newAppelDechet;
		});
  };

  // Etat pour stocker les voyages
  const [appelVoyages, setAppelDechet] = useState(() => [
    {
  },
  ]);

  // Méthode pour stocker tous les appels API 
  const addToAppelDict = (appelsDechet) => {
    setAppelDict(prev => {
        const newAppelDict = { ...prev, 'dechet': appelsDechet };
        console.log("Appel Dict", newAppelDict);
        return newAppelDict;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    setDisplayBilan(true);
    console.log(formSubmitted);
    setFormStatus({
      ...formStatus,
      dechet: 'fini',
    });
  };

  useEffect(() => {
    setFormStatus({
        ...formStatus,
        dechet: 'en cours',
    });
   }, []);


 useEffect(() => {
   if (displayBilan) {
     setFormStatus({
       ...formStatus,
       dechet: 'fini',
     });
   } else if (formSubmitted) {
     setFormStatus({
       ...formStatus,
       dechet: 'en cours',
     });
   }
 }, [formSubmitted]);

  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate("/bilan");
  };

  const [lancer, setLancer] = useState(false);
  const [apiResponseDechet, setApiResponseDechet] = useState(null); // État pour stocker la réponse de l'API
   

  // Fonction pour mettre à jour l'état de la réponse de l'API
  const updateApiResponseDechet = (response) => {
    setApiResponseDechet(response);
  };


  const handleButtonClickAPI = () => {
    setLancer(true);
    setDisplayBilan(true);
    setFormSubmitted(false);
    // setResponseData(null); // Réinitialiser les données avant l'appel API
  };

  const [compteurDechet, setCompteurDechet] = useState(() => cache.compteurDechet || {
    Mobilier: 0,
    MobilierScM: 0,
    Electronique: 0,
    ElectroniqueScM: 0,
    Vetement: 0,
    VetementScM: 0,
    TriPlastique: 0,
    TriPapier: 0,
    TriDechet: 0,
    TriCarton: 0,
    TriVerre: 0,
    Ampoule: 0,
    Medicament: 0,
    Elect: 0,
    Bricolage: 0,
    Pile: 0,
    VetementFripe:0,
    MeubleDecharge:0

  });

  // Update cache when voyages change
  useEffect(() => {
  setCache(prevCache => ({
    ...prevCache,
    compteurDechet,
    displayBilan
  }));
  setCacheCustom()
  }, [
    compteurDechet,
    displayBilan
  ]);

  const handleValeurChange = (nom, valeur) => {
    const [index, value] = valeur;

    setCompteurDechet((prevCompteur) => {
      const updatedCompteur = {...prevCompteur};
      updatedCompteur[nom] = value;
      return updatedCompteur;
    });
  };

  console.log(compteurDechet)

  return (
    <form onSubmit={handleSubmit}>
      <div className="conso">
      <Titre Titre="Consommation" />
      <div className="h61">
      Détaillez vos achats
      </div>
      <div className="row">
          <div className="col-md-6 col-lg col-12">
          <CarreLogoInput2 logo={LogoCanape} titregras="Mobilier" titre="Dépense annuelle en mobilier" currentValeur={compteurDechet.Mobilier} nombre={1} unit="€" onChange={(valeur) =>
                    handleValeurChange('Mobilier', valeur) 
                    } />
          </div>
          <div className="col-md-6 col-lg col-12">
            <CarreLogoInput2 logo={LogoCanape} secondeMain = {true} titregras="Mobilier seconde main" titre="Dépense annuelle en mobilier de seconde main" currentValeur={compteurDechet.MobilierScM} nombre={1} unit="€" onChange={(valeur) =>
                    handleValeurChange('MobilierScM', valeur) 
                    } />
          </div>
          <div className="col-md-6 col-lg col-12">
            <CarreLogoInput2 logo={LogoMicroOnde} titregras="électronique et appareils électriques" titre="Dépense annuelle en électroménager et électronique" currentValeur={compteurDechet.Electronique} nombre={1} unit="€" onChange={(valeur) =>
                    handleValeurChange('Electronique', valeur) 
                    } />
          </div>
          <div className="col-md-6 col-lg col-12">
            <CarreLogoInput2 logo={LogoMicroOnde} secondeMain = {true} titregras="électronique reconditionné" titre="Dépense annuelle en électroménager et électronique reconditionné" currentValeur={compteurDechet.ElectroniqueScM} nombre={1} unit="€" onChange={(valeur) =>
                    handleValeurChange('ElectroniqueScM', valeur) 
                    } />
          </div>
        </div>
        <div className="row" style={{marginBottom : "8vh"}}>
          <div className="col-md-6 col-lg col-12">
            <CarreLogoInput2 logo={LogoVetement} titregras="Vêtements neufs" titre="Dépense annuelle en vêtements neufs" currentValeur={compteurDechet.Vetement} nombre={1} unit="€" onChange={(valeur) =>
                    handleValeurChange('Vetement', valeur) 
                    } />
          </div>
          <div className="col-md-6 col-lg col-12">
          <CarreLogoInput2 logo={LogoVetement} secondeMain = {true} titregras="Vêtements seconde main" titre="Dépense annuelle en vêtements de seconde main" currentValeur={compteurDechet.VetementScM} nombre={1} unit="€" onChange={(valeur) =>
                    handleValeurChange('VetementScM', valeur) 
                    } />
          </div>
          <div className="col-md-6 col-lg col-12">
          <div className='p3' style={{ margin : "5vh", backgroundColor: "#EBE085", padding: "10%", fontWeight: "900", fontSize: "14px" }}>
              Les achats de seconde main ont une empreinte carbone nulle.
            </div>
          </div>
          <div className="col-lg"></div>
        </div>


        <Titre Titre="Tri et déchets" />
        <div className="h61">
          Pour chaque matière, indiquez le pourcentage de déchets quotidiens que vous jetez dans la bonne poubelle de tri par rapport au pourcentage mis dans la poubelle des ordures ménagères.
          <br />Si vous triez l'intégralité de votre plastique en le mettant dans la bonne poubelle, mettez 100%. 
        </div>
        <div className="row">
          <div className="col-md-6 col-lg col-12">
            <CarreLogoInput2 logo={LogoPlastique} titregras="Plastique" titre="Poubelle recyclable" currentValeur={compteurDechet.TriPlastique} nombre={1} unit="%" verification = {true} onChange={(valeur) =>
                    handleValeurChange('TriPlastique', valeur) 
                    }/>
          </div>
          <div className="col-md-6 col-lg  col-12">
            <CarreLogoInput2 logo={LogoPapier} titregras="Papier/journaux" titre="Poubelle recyclable" currentValeur={compteurDechet.TriPapier} nombre={1} unit="%" verification = {true} onChange={(valeur) =>
                    handleValeurChange('TriPapier', valeur) 
                    }/>
          </div>
          <div className="col-md-6 col-lg  col-12">
            <CarreLogoInput2 logo={LogoPlante} titregras="Déchets verts" titre="Compost" currentValeur={compteurDechet.TriDechet} nombre={1} unit="%" verification = {true} onChange={(valeur) =>
                    handleValeurChange('TriDechet', valeur) 
                    }/>
          </div>
          <div className="col-md-6 col-lg  col-12">
            <CarreLogoInput2 logo={LogoCarton} titregras="Emballages/carton" titre="Poubelle recyclable" currentValeur={compteurDechet.TriCarton} nombre={1} unit="%" verification = {true} onChange={(valeur) =>
                    handleValeurChange('TriCarton', valeur) 
                    }/>
          </div>
          <div className="col-md-6 col-lg  col-12">
            <CarreLogoInput2 logo={LogoVerre} titregras="Verre" titre="Poubelle recyclable" currentValeur={compteurDechet.TriVerre} nombre={1} unit="%" verification = {true} onChange={(valeur) =>
                    handleValeurChange('TriVerre', valeur) 
                    }/>
          </div>
        </div>
        <div className="h61">
          Certains produits sont soumis à la responsabilité élargie du producteur et doivent être amenés sur des lieux de dépots pour un bon recyclage. D’autres sont redonnés. Indiquez le pourcentage de produits redéposés dans un conteneur adapté.
        </div>
        <div className="row">
          <div className="col-md-6 col-lg col-12">
            <CarreLogoInput2 logo={LogoAmpoule} titregras="Ampoules" titre="Bornes en supermarché" currentValeur={compteurDechet.Ampoule} nombre={1} unit="ampoule(s)" onChange={(valeur) =>
                    handleValeurChange('Ampoule', valeur) 
                    }/>
          </div>
          <div className="col-md-6 col-lg col-12">
            <CarreLogoInput2 logo={LogoVetement} titregras="Vêtements" titre="Friperie ou bornes" currentValeur={compteurDechet.VetementFripe} nombre={1} unit="kg" onChange={(valeur) =>
                    handleValeurChange('VetementFripe', valeur) 
                    }/>
          </div>
          <div className="col-md-6 col-lg col-12">
            <CarreLogoInput2 logo={LogoMedicament} titregras="Médicaments" titre="Pharmacie" currentValeur={compteurDechet.Medicament} nombre={1} unit="boite(s)" onChange={(valeur) =>
                    handleValeurChange('Medicament', valeur) 
                    }/>
          </div>
          <div className="col-md-6 col-lg col-12">
            <CarreLogoInput2 logo={LogoMicroOnde} titregras="électronique et appareils électriques" titre="décharge ou déchetterie" currentValeur={compteurDechet.Elect} nombre={1} unit="appareil(s)" onChange={(valeur) =>
                    handleValeurChange('Elect', valeur) 
                    } />
          </div>
          <div className="col-md-6 col-lg col-12">
            <CarreLogoInput2 logo={LogoCanape} titregras="Mobilier" titre="décharge ou déchetterie" currentValeur={compteurDechet.MeubleDecharge} nombre={1} unit="meuble(s)" onChange={(valeur) =>
                    handleValeurChange('MeubleDecharge', valeur) 
                    } />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg col-12">
            <CarreLogoInput2 logo={LogoPeiture} titregras="Bricolage, peinture" titre="Déchetterie" currentValeur={compteurDechet.Bricolage} nombre={1} unit="kg" onChange={(valeur) =>
                    handleValeurChange('Bricolage', valeur) 
                    } />
          </div>
          <div className="col-md-6 col-lg col-12">
            <CarreLogoInput2 logo={LogoPile} titregras="Piles" titre="Bornes en supermarché" currentValeur={compteurDechet.Pile} nombre={1} unit="pile(s)" onChange={(valeur) =>
                    handleValeurChange('Pile', valeur) 
                    }/>
          
          </div>
          <div className="col-lg"></div>
          <div className="col-lg"></div>
          <div className="col-lg"></div>
        </div>
        
          {/* <div className="col-lg-3 col-md-12" style={{ height: "150px", paddingLeft: "50px" }}>
            <div className='p3' style={{ backgroundColor: "#EBE085", padding: "10%", fontWeight: "900", fontSize: "14px" }}>
              Les achats de seconde main ont une empreinte carbone nulle.
            </div>
          </div> */}
        <div className="row">
          <div className="col-12">
            <Bouton titre="CALCULER" onClick={handleButtonClickAPI}/>


            <AppelAPIDechet compteurDechet={compteurDechet} setLancer={setLancer} Lancer={lancer} save={false} setApiResponse={updateApiResponseDechet} setAppelDechet={updateAppelDechet}/> 
            {/* {console.log(apiResponseDechet)}
            {apiResponseDechet && (
            <div>
              <h2>Réponse de l'API :</h2>
              <pre>{JSON.stringify(apiResponseDechet, null, 2)}</pre>
            </div>
          )} */}
          
          </div>
        </div>

        {displayBilan && (
          <div>
            <div className="row">
              <div className="col-12">
                <BilanInter numeroEtape={7} voyage="non" responseAPIDechet={apiResponseDechet} />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <Bouton titre="Catégorie suivante" onClick={handleButtonClick} />
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default FormDechet;

