import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth } from '../Authentification/Auth';
import { IdBCContext } from '../IdBCContext';
import { useFormData } from '../FormDataContext';
import API_BASE_URL from './config';

const AppelAPIVoitureId = ({index,idVoiture,voyage,typeVehicule,typeCarburant,valeurInput,selectedUnite, consommation,kilometrageConnu, unit, save, setLancer, Lancer, setApiResponse, setConsoVoyage, setAppelVoiture, setIdVoiture, filledData, usedUrl, lancerSave, setLancerSave, id}) => {
  const { token, fetchToken, isTokenExpired } = useAuth();
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { idBC } = useContext(IdBCContext);
  const {formData, setFormData} = useFormData();

  useEffect(() => {
    isTokenExpired()
  }, []);
  
  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    if (token && Lancer) { 
       fetchData();
       setLancer(false);
     }
  }, [token, Lancer]); // Se ré-exécute lorsque `token` change


   const fetchData = async () => {
     setLoading(true);
    const apiUrl = `${API_BASE_URL}/aps_Pvoiture_Global`;

    const data =  {
      IdBC: idBC,
      IdPage: "Pvoiture",
      ConsoConnue: consommation !== '',
      TypeVehicule: typeVehicule,
      TypeEnergie: typeCarburant,
      Consommation: consommation || 0,
      UnitConsommation: unit,
      KilometrageConnu: true,
      Kilometrage: valeurInput,
      unit: selectedUnite, 
      save: save,
    }

    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };

    //Faire le if sur la condition km connu =true
    try {
      const result = await axios.post(apiUrl, data, options);

      // Mettre à jour la réponse de l'API dans le composant parent
        setIdVoiture(result.data.IdVoiture !== undefined ? result.data.IdVoiture : "")

       setLancer(false);

    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse('Erreur lors de la récupération des données.');
    } finally {
      setLoading(false);
    }
  };
  return (null);
};

export default AppelAPIVoitureId;
