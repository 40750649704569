import React, { createContext, useState } from 'react';

const CacheContextDechets = createContext();

export const CacheDechetsProvider = ({ children }) => {
		const [cache, setCache] = useState(JSON.parse(sessionStorage.getItem("cacheDechets")) || {});

	const setCacheCustom = () => {
		sessionStorage.setItem("cacheDechets", JSON.stringify(cache))
	}

	const clearCacheDechets = () => {
		setCache({})
	}

	return (
		<CacheContextDechets.Provider value={{ cache, setCache, setCacheCustom, clearCacheDechets}}>
			{children}
		</CacheContextDechets.Provider>
	);
};

export default CacheContextDechets;
