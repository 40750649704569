import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth } from '../Authentification/Auth';
import { IdBCContext } from '../IdBCContext';
import API_BASE_URL from './config';

const AppelAPIDomicile = ({domicileData, setLancer, Lancer, save, setApiResponse, setAppelDomicile,  filledData, usedUrl, lancerSave, setLancerSave}) => {
  const { token, fetchToken, isTokenExpired } = useAuth();
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { idBC } = useContext(IdBCContext);

  useEffect(() => {
    isTokenExpired()
  }, []);

  
  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    if (token && Lancer) { 
       fetchData();
     }
  }, [token, Lancer]); // Se ré-exécute lorsque `token` change

  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    if(token && lancerSave){
       fetchDataToSave(filledData, usedUrl);
       setLancerSave(false)
     }
  }, [token, lancerSave]); // Se ré-exécute lorsque `token` change


  const fetchDataToSave = async (filledData, usedUrl) => {
    setLoading(true);
    filledData.save = true
    
    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    
    try {
      const result = await axios.post(usedUrl, filledData, options);

    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse('Erreur lors de la récupération des données.');

    } finally {
      setLoading(false);
      setLancerSave(false)    
    }
  }

   const fetchData = async () => {
     setLoading(true);
    const apiUrl = `${API_BASE_URL}/aps_PDomicile_Global`;
    
    const data = {
      IdBC: idBC, 
      TypeDeLogement: domicileData.TypeDeLogement,
      NbPersonnes: domicileData.NbPersonnes,
      Surface: domicileData.Surface,
      ConsommationConnue: domicileData.ConsommationConnue,
      Etiquette: domicileData.Etiquette,
      DateConstruction: domicileData.DateConstruction,
      Renovation: domicileData.Renovation,
      Pays: domicileData.Pays,
      ElectriciqueEuro: domicileData.ElectriciqueEuro,
      ElectriciqueUnit: domicileData.ElectriciqueUnit,
      ChauffageUrbainEuro: domicileData.ChauffageUrbainEuro,
      ChauffageUrbainUnit: domicileData.ChauffageUrbainUnit,
      GplEuro: domicileData.GplEuro,
      GplUnit: domicileData.GplUnit,
      GazEuro: domicileData.GazEuro,
      GazUnit: domicileData.GazUnit,
      BoisEuro: domicileData.BoisEuro,
      BoisUnit: domicileData.BoisUnit,
      FioulEuro: domicileData.FioulEuro,
      FioulUnit: domicileData.FioulUnit,
      PanneauSol: domicileData.PanneauSol,
      PanneauPhoto: domicileData.PanneauPhoto,
      Eolienne: domicileData.Eolienne,
      ChauffageElectrique: domicileData.ChauffageElectrique,
      ChauffageFioulCollectif: domicileData.ChauffageFioulCollectif,
      ChauffageFioulIndividuel: domicileData.ChauffageFioulIndividuel,
      ChauffageGazCollectif: domicileData.ChauffageGazCollectif,
      ChauffageGazIndividuel: domicileData.ChauffageGazIndividuel,
      ChauffageGPL: domicileData.ChauffageGPL,
      ChauffageReseau: domicileData.ChauffageReseau,
      save: save
    };
    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    
    try {
      const resultDomicile = await axios.post(apiUrl, data, options);
      // Stocker à jour l'appel à l'API pour plus tard
      setAppelDomicile(0, data, apiUrl)
       // Mettre à jour la réponse de l'API dans le composant parent
       setApiResponse(resultDomicile.data);
       setLancer(false);
    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse('Erreur lors de la récupération des données.');
    } finally {
      setLoading(false);
    }
  };
  
  
  return (null);
};

export default AppelAPIDomicile;
