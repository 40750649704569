import React, { createContext, useState } from 'react';

const CacheContextPlacement = createContext();

export const CachePlacementProvider = ({ children }) => {
		const [cache, setCache] = useState(JSON.parse(sessionStorage.getItem("cachePlacement")) || {});

	const setCacheCustom = () => {
		sessionStorage.setItem("cachePlacement", JSON.stringify(cache))
	}

	const clearCachePlacement = () => {
		setCache({})
	}

	return (
		<CacheContextPlacement.Provider value={{ cache, setCache, setCacheCustom, clearCachePlacement }}>
			{children}
		</CacheContextPlacement.Provider>
	);
};

export default CacheContextPlacement;
