import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Bouton from '../Bouton';
import SousTitre from '../../SousTitre';
import velo from "../../img/velo.png";
import veloelect from "../../img/veloel.png";
import pied from "../../img/pied.png";
import Trottinette from "../../img/Trottinette.png";

import "./form.css"
import CarreLogoInput2 from '../../CarreLogo/CarreLogoInput2';
import BilanInter from '../../BilanInter';
import AppelAPITC2 from '../../Api/AppelAPITC2';
import { useFormProgress } from '../../FormProgressContext';
import CacheContextTC from '../../../components/cachescontext/contextTC';
import {useAppelDict} from '../../BilanInter/appelDictContext'


const FormTC2 = ({consoTC1}) => {
    const { cache, setCache, setCacheCustom } = useContext(CacheContextTC);

    const navigate = useNavigate();
    const [formSubmitted, setFormSubmitted] = useState(false); // nouvel état pour suivre la soumission du formulaire
    const [displayBilan, setDisplayBilan] = useState(() => cache.displayBilan || false);
    const { formStatus, setFormStatus } = useFormProgress();
    const [lancer, setLancer] = useState(false);
    const [apiResponseTC1, setApiResponseTC1] = useState(consoTC1); // État pour stocker la réponse de l'API
    const [apiResponse, setApiResponse] = useState(consoTC1); // État pour stocker la réponse de l'API

    const { appelDict, setAppelDict } = useAppelDict();

    // Méthode pour stocker les appels fait à voiture
    const updateAppelTC2 = (index, appelTC2, apiUrl) => {
            setAppelTC2((prevAppelTC2) => {
                const newAppelTC2 = [...prevAppelTC2];
                    newAppelTC2[index] = { ...newAppelTC2[index], appelTC2: appelTC2, apiUrl: apiUrl};
                addToAppelDict(newAppelTC2)
                    return newAppelTC2;
            });
    };

    // Etat pour stocker les voyages
    const [appelVoyages, setAppelTC2] = useState(() => [
        {
    },
    ]);

    // Méthode pour stocker tous les appels API 
    const addToAppelDict = (appelsTC2) => {
        setAppelDict(prev => {
            const newAppelDict = { ...prev, 'TC2': appelsTC2 };
            console.log("Appel Dict", newAppelDict);
            return newAppelDict;
        });
    };
    
    const handleSubmit = (event) => {
        event.preventDefault();
        setFormSubmitted(true);
        setDisplayBilan(true);
         // indique que le formulaire a été soumis SetDisplay Bilan (true) quand reponse api je repasse à false. le display de bilan inter se fera sur displaybilan
    }
    

    const handleButtonClick = (event) => {
        event.preventDefault();
        navigate("/alimentaire"); // Ajout de la navigation après la soumission du formulaire
    };
     

    // Fonction pour mettre à jour l'état de la réponse de l'API et addition avec la consommation de la page d'avant
    const updateApiResponse = (response) => {
        const updatedConso = apiResponseTC1 + response
        setApiResponse(updatedConso);
    };


    const handleButtonBack = () => {
        navigate("/TC-2")
    };

    const handleButtonClickAPI = () => {
        setLancer(true);
        setDisplayBilan(true);
        setFormSubmitted(false);
        setFormStatus({ ...formStatus, tc: 'fini' });
        // Vous pouvez effectuer ici des traitemensts supplémentaires avec les arguments si nécessaire
        //setApiResponse(null); // Réinitialiser les données avant l'appel API
    };

    const [compteur, setCompteur] = useState(() => cache.compteur || {
        velo: '0',
        veloElectrique: '0',
        trotinetteElectrique: '0',
        pied:'0'
    });

    useEffect(() => {
      setCache(prevCache => ({
        ...prevCache,
        compteur,
        displayBilan
      }));
      setCacheCustom()
      }, [
        compteur,
        displayBilan
      ]);

    // console.log(apiResponse);
    const handleValeurChange = (nom, valeur) => {
        const [index, value] = valeur;
        setCompteur((prevCompteur) => {
            const updatedCompteur = { ...prevCompteur };
            updatedCompteur[nom] = value;
            return updatedCompteur
        })
    };

    useEffect(() => {
        setFormStatus({
                ...formStatus,
                tc: 'en cours',
        });
     }, []);


 useEffect(() => {
     if (displayBilan) {
         setFormStatus({
             ...formStatus,
             tc: 'fini',
         });
     } else if (formSubmitted) {
         setFormStatus({
             ...formStatus,
             tc: 'en cours',
         });
     }
 }, [formSubmitted]);

    return (
        <form onSubmit={handleSubmit}>
            <div className="Cadre">
                
                <div className="row">
                    <SousTitre SousTitre="Autres modes de transport - mobilité douce" place="center" />
                </div>

                <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                        <CarreLogoInput2 titregras="Vélo" logo={velo} currentValeur={compteur.velo} nombre={1} unit="km/an" onChange={(valeur) =>
                                        handleValeurChange('velo', valeur)} />
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <CarreLogoInput2 titregras="Vélo électrique" logo={veloelect} currentValeur={compteur.veloElectrique} nombre={1} unit="km/an" onChange={(valeur) =>
                                        handleValeurChange('veloElectrique', valeur)}/>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <CarreLogoInput2 titregras="A pied" logo={pied} currentValeur={compteur.pied} nombre={1} unit="km/an" onChange={(valeur) =>
                                        handleValeurChange('pied', valeur)} />
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <CarreLogoInput2 titregras="Trottinette electrique" logo={Trottinette} currentValeur={compteur.trotinetteElectrique} nombre={1} unit="km/an" onChange={(valeur) =>
                                        handleValeurChange('trotinetteElectrique', valeur)}/>
                    </div>
                </div>
            </div>

            <div className="bottom-bouton" style={{ marginTop: "7vh" }}>
                <div>
                    <Bouton titre="PAGE PRECEDENTE" onClick={handleButtonBack}/>
                </div>
                <div>
                    <Bouton titre="CALCULER" onClick={handleButtonClickAPI}/>
                </div>
            </div>

            {displayBilan && (
                <div>
                    <AppelAPITC2 compteur={compteur} setLancer={setLancer} Lancer={lancer} save={false} setApiResponse={updateApiResponse} setAppelTC2={updateAppelTC2}/>
                </div>
            )}

            {displayBilan && (
                <div>
                    <div className="row">
                        <div className="col-12">
                            <BilanInter numeroEtape={4} voyage="non" responseAPITC={apiResponse} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <Bouton titre="Catégorie suivante" onClick={handleButtonClick} />
                        </div>
                    </div>
                </div>
            )}
        </form>
    );
};

export default FormTC2;
