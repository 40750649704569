import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth } from '../../Authentification/Auth';
import { IdBCContext } from '../../IdBCContext';
import API_BASE_URL from '../config';

const AppelAPIBilanDomicile = ({setLancer, Lancer, setApiResponse, save}) => {
  const { token, fetchToken, isTokenExpired } = useAuth();
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { idBC } = useContext(IdBCContext);

  useEffect(() => {
    isTokenExpired()
  }, []);

  
  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    if (token && Lancer) { 
       fetchData();
     }


  }, [token, Lancer]); // Se ré-exécute lorsque `token` change

   const fetchData = async () => {
     setLoading(true);
    const apiUrl = `${API_BASE_URL}/aps_PDomicile_Total`;
    
    const data = {
      IdBC: idBC,
      // save: save
    };

    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    
    try {
      const result = await axios.post(apiUrl, data, options);
       // Mettre à jour la réponse de l'API dans le composant parent
       setApiResponse(result.data);
       setLancer(false);

    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse('Erreur lors de la récupération des données.');
    } finally {
      setLoading(false);
    }
  };
  return (null);
};

export default AppelAPIBilanDomicile;
