import React, { createContext, useState } from 'react';

const CacheContextTC = createContext();

export const CacheTCProvider = ({ children }) => {
		const [cache, setCache] = useState(JSON.parse(sessionStorage.getItem("cacheTC")) || {});

	const setCacheCustom = () => {
		sessionStorage.setItem("cacheTC", JSON.stringify(cache))
	}

	const clearCacheTC = () => {
		setCache({})
	}

	return (
		<CacheContextTC.Provider value={{ cache, setCache, setCacheCustom, clearCacheTC }}>
			{children}
		</CacheContextTC.Provider>
	);
};

export default CacheContextTC;
