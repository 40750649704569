import React, { useState } from "react";
import CookieConsent from "react-cookie-consent";
import "./CookieBanner.css"; // Import du fichier CSS

const CookieBanner = () => {
  const [showPreferences, setShowPreferences] = useState(false);

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Accepter"
        declineButtonText="Refuser"
        enableDeclineButton
        disableStyles
        cookieName="userConsent"
        expires={150}
        overlay
        containerClasses="cookie-banner"
        contentClasses="cookie-content"
        buttonClasses="cookie-button accept"
        declineButtonClasses="cookie-button decline"
      >
        <h2>Gérer le consentement</h2>
        <p>
          Pour offrir les meilleures expériences, nous utilisons des technologies telles que les cookies pour
          stocker et/ou accéder aux informations des appareils. Le fait de consentir à ces technologies nous
          permettra de traiter des données telles que le comportement de navigation ou les ID uniques sur ce site.
        </p>
        <div className="cookie-links">
          <a href="/politique-cookies-calculateur.docx">Politique cookies</a> |{" "}
          <a href="/politique-confidentialite-calculateur.docx">Politique de confidentialité</a>
        </div>
        {/* <button className="cookie-button preferences" onClick={() => setShowPreferences(!showPreferences)}>
          Voir les préférences
        </button> */}
      </CookieConsent>

      {showPreferences && (
        <div className="cookie-preferences">
          <h3>Préférences des cookies</h3>
          <p>Gérez vos préférences ici...</p>
          <button onClick={() => setShowPreferences(false)} className="cookie-button close">
            Fermer
          </button>
        </div>
      )}
    </>
  );
};

export default CookieBanner;
