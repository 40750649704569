import React from 'react';
import { useNavigate } from 'react-router-dom';
import Titre from '../Titre';
import PetiteCard from '../PetiteCard/PetiteCard';
import SousTitre from '../SousTitre';
import Bouton from '../Form/Bouton';
import './ContenueLogo.css';
import { useFormProgress } from '../FormProgressContext'; // Mettez à jour ce chemin


const ContenueBouton = ({ petiteCards, TitreP, SousTitreP, lien, formType, clearButtonChoix }) => {
  const navigate = useNavigate();
  const { formStatus, setFormStatus } = useFormProgress();

  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate(lien);
  };

  const handlePetiteCardClick = (label) => {
    if (label === 'NON' || label === 'JAMAIS') {
      setFormStatus({
        ...formStatus,
        [formType]: 'fini',
      });
    }

    if (clearButtonChoix){
      clearButtonChoix(label)
    }
  };

  return (
    <div className="contenue-bouton">
      {/* Titre */}
      <Titre Titre={TitreP} className={TitreP} couleur="#000000" place="center" />

      {/* Sous-titre */}
      <SousTitre SousTitre={SousTitreP} className="SousTitre" place="center" />

      <div className="row petite-card-container justify-content-center">
        {/* Affichage des petites cartes */}
        {petiteCards.map((petiteCard, index) => (
          <div key={index} className="col-md-3 col-sm-6 col-12">
            <PetiteCard label={petiteCard.label} 
            imageName={petiteCard.imageName} 
            lien={petiteCard.lien}
            onClick={() => handlePetiteCardClick(petiteCard.label)} 
            previousChoice={petiteCard.previousChoice}
             />
          </div>
        ))}
      </div>

      <div className="row justify-content-end mt-4">
        <div className="col-md-6 col-sm-6 col-12 text-right">
          {/* Bouton Catégorie suivante */}
          <Bouton titre="Catégorie suivante" onClick={handleButtonClick} />
        </div>
      </div>
    </div>
  );
}

export default ContenueBouton;



