import React, { createContext, useState } from 'react';

const CacheContextAlimentaire = createContext();

export const CacheAlimentaireProvider = ({ children }) => {
	const [cache, setCache] = useState(JSON.parse(sessionStorage.getItem("cacheAlimentaire")) || {});

	const setCacheCustom = () => {
		sessionStorage.setItem("cacheAlimentaire", JSON.stringify(cache))
	}

	const clearCacheAlimentaire = () => {
		setCache({})
	}

	return (
		<CacheContextAlimentaire.Provider value={{ cache, setCache, setCacheCustom, clearCacheAlimentaire }}>
			{children}
		</CacheContextAlimentaire.Provider>
	);
};

export default CacheContextAlimentaire;
